import gql from "graphql-tag";

export default gql(`
  query ($nextToken: String, $limit:Int,$formid:ID,$userid:ID){
    formEntries(nextToken: $nextToken, limit: $limit,  formid:$formid,userid:$userid) {
      nextToken
      totalCount
      data{
        __typename
        id
        created_at
        modified_at
        userid
        status
        data
        formId
        creator
        formTitle
      }
    }
  }`);
