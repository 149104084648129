import FormList from "../views/FormList/FormList";
import HealthForm from "../views/Form/Form";
import ConfigForm from "../views/Form/ConfigForm";
import EntryList from "../views/EntryList/EntryList";
import FormInvite from "../views/FormInvite/FormInvite";

const dashboardRoutes = [
  {
    path: "/forms",
    sidebarName: "FORMS",
    navbarName: "FORMS",
    icon: "note",
    component: FormList,
    routes: [
      {
        path: "/forms/entries",
        component: EntryList
      },
      {
        path: "/forms/entries/:id",
        component: HealthForm
      },
      {
        path: "/forms/config",
        component: ConfigForm
      },
      {
        path: "/forms/invite",
        component: FormInvite
      },
      {
        path: "/forms/:id",
        component: HealthForm
      }
    ]
  },
  // {
  //   path: "https://faq.neami.blue/index.html",
  //   sidebarName: "FAQ",
  //   navbarName: "FAQ",
  //   icon: "live_help"
  // },
  {
    redirect: true,
    path: "/",
    to: "/forms",
    navbarName: "Redirect"
  }
];

export default dashboardRoutes;
