import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Checkbox from "@material-ui/core/Checkbox";
import { graphql, withApollo } from "react-apollo";
import TableCell from "@material-ui/core/TableCell";
import tableStyle from "assets/jss/material-dashboard-react/tableStyle";
import NoDataFound from "components/NoDataFound/NoDataFound";
import QueryFormData from "GraphQL/QueryFormData";
import EnhancedTableHead from "components/EnhancedTableHead/EnhancedTableHead";
import EnhancedTableToolbar from "components/EnhancedTableToolbar/EnhancedTableToolbar";
import CircularIndeterminate from "components/CircularProgress/CircularIndeterminate";
import Excel from "components/Download/Excel";

const PAGE_LIMIT = 100;
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headerRows = [
  {
    id: "creator",
    string: false,
    disablePadding: false,
    label: "SUBMITTED BY",
    canSort: true
  },
  {
    id: "created_at",
    string: false,
    disablePadding: false,
    label: "SUBMITTED ON",
    canSort: true
  },
  {
    id: "id",
    string: false,
    disablePadding: false,
    label: "REFERENCE ID",
    canSort: true
  }
];

class EnhancedTable extends React.Component {
  state = {
    busy: false,
    order: "desc",
    orderBy: "created_at",
    selected: [],
    selectedData: [],
    page: 0,
    rowsPerPage: 25,
    nextToken: this.props.nextToken
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    const { page: currPage } = this.state;
    if (page > currPage) {
      this.fetchMore();
    }
    this.setState({ page });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      const selected = this.props.data.map(n => n.id);
      const selectedData = this.props.data
        .filter(n => {
          return selected.includes(n.id);
        })
        .map(n => {
          return n.data;
        });
      this.setState(state => ({
        selected: selected,
        selectedData: selectedData
      }));
      return;
    }
    this.setState({ selected: [], selectedData: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const selectedData = this.props.data
      .filter(n => {
        return newSelected.includes(n.id);
      })
      .map(n => {
        return n.data;
      });
    this.setState(state => ({
      selected: selected,
      selectedData: selectedData
    }));

    this.setState({ selected: newSelected, selectedData: selectedData });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  fetchMore = () => {
    const { fetchMore, nextToken } = this.props;
    fetchMore({
      variables: {
        nextToken,
        limit: PAGE_LIMIT
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const { formEntries: { data = {} } = {} } = fetchMoreResult;

        const {
          formEntries: { data: preData }
        } = prev;

        return {
          formEntries: {
            ...prev.formEntries,
            ...fetchMoreResult.formEntries,
            data: [...preData, ...data]
          }
        };
      }
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      classes,
      loading,
      error,
      totalCount,
      data,
      location: {
        pathname,
        state: { form, formTitle }
      }
    } = this.props;

    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const formid = form ? form.id : null;

    if (error) {
      return <NoDataFound title={"Form render failed."} />;
    }

    if (!data || loading) {
      return <CircularIndeterminate width={"250px"} />;
    }

    const rootLink = pathname.split("/")[1];

    const correctCase =
      rootLink && rootLink.charAt(0).toUpperCase() + rootLink.substr(1);

    const getCorrectDateFormat = dateString => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, 0);
      const day = date.getDate();

      return `${day}/${month}/${year}`;
    };

    // get last 3 char as shorten ID
    const getShortenId = id => id.substr(-3);

    // download all data
    const allDataArr = data.map(d => d.data);
    // displayData
    const displayData = stableSort(data, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.breadCrumbsWrapper}>
              <Link
                to={{
                  pathname: "/"
                }}
              >
                <span className={classes.breadCrumbsInactive}>
                  {correctCase}
                </span>
              </Link>
              <ArrowRight className={classes.breadCrumbIcon} />
              <span className={classes.breadCrumbsActive}>
                Entries for the {formTitle} form
              </span>
            </div>
          </Grid>
        </Grid>
        {data ? (
          <GridItem xs={12}>
            <div
              style={{
                justifyContent: "flex-end",
                textAlign: "right",
                display: "flex",
                alignItems: "center"
              }}
            >
              <span style={{ marginRight: "0.5rem" }}>Download all:</span>
              <Excel data={allDataArr} />
            </div>
          </GridItem>
        ) : null}
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            formTitle={formTitle}
            exportData={this.state.selectedData}
          />
          <Table aria-labelledby="tableTitle" className={classes.table}>
            <EnhancedTableHead
              multiSelect={true}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              headerRows={headerRows}
            />
            <TableBody>
              {displayData.map((data, index) => {
                const isSelected = this.isSelected(data.id);
                return (
                  <TableRow
                    className={classes.row}
                    hover
                    tabIndex={-1}
                    key={index}
                    onClick={event => this.handleClick(event, data.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} color="default" />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.creator}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {getCorrectDateFormat(data.created_at)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Link
                        to={{
                          pathname: `/forms/entries/${data.id}`,
                          state: { data, form }
                        }}
                      >
                        {getShortenId(data.id)}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            rowsPerPageOptions={[25, 50, 100]}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  nextToken: PropTypes.string,
  forms: PropTypes.array,
  fetchMore: PropTypes.func,
  totalCount: PropTypes.number,
  location: PropTypes.object,
  data: PropTypes.array
};

export default withApollo(
  graphql(QueryFormData, {
    options: ({ location: { state }, myAccInfo }) => {
      const { form } = state;
      const formid = form ? form.id : null;
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          nextToken: "",
          limit: PAGE_LIMIT,
          formid,
          userid: "",
          filter: ""
        }
      };
    },
    props: ({ data: { loading, error, formEntries: formData, fetchMore } }) => {
      if (formData) {
        const { nextToken, data, totalCount } = formData;
        return {
          loading,
          error,
          data,
          totalCount,
          nextToken,
          fetchMore
        };
      }
      return {
        loading,
        error,
        data: null,
        nextToken: null,
        fetchMore: null
      };
    }
  })(withStyles(tableStyle, { withTheme: true })(EnhancedTable))
);
