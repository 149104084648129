//import Tags from "components/Form/Tags";

const currentURL = window.location.protocol + "//" + window.location.host;

const GlobalConfig = {
  formDataTableName: "healthCareFormData",
  appName: "HEALTHCARE",
  adminRole: "ADMIN",
  dev: {
    amp: {
      REACT_APP_AWS_AUTH_REGION: "ap-southeast-2",
      REACT_APP_USER_POOL_ID: "ap-southeast-2_EHn8sCFSJ",
      REACT_APP_CLIENT_APP_ID: "5sbs3b87bcmaoq7hrfohh4gh8e"
    },
    oauth: {
      domain: "ehealth.auth.ap-southeast-2.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${currentURL}`,
      redirectSignOut: `${currentURL}/logout`,
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    },
    appSync: {
      REACT_APP_GRAPHQL_ENDPOINT:
        "https://2tn2nbbfhjf4hms76qtcfiwbwi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
      REACT_APP_AWS_CLIENT_REGION: "ap-southeast-2"
    }
  },
  production: {
    amp: {
      REACT_APP_AWS_AUTH_REGION: "ap-southeast-2",
      REACT_APP_USER_POOL_ID: "ap-southeast-2_w3YQ857Nu",
      REACT_APP_CLIENT_APP_ID: "76st64q81o46eep3jvdu0lk5te"
    },
    oauth: {
      domain: "ehealth-prod.auth.ap-southeast-2.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${currentURL}`,
      redirectSignOut: `${currentURL}/logout`,
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    },
    appSync: {
      REACT_APP_GRAPHQL_ENDPOINT:
        "https://pgcadv3ijbak7fruyxksidamu4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
      REACT_APP_AWS_CLIENT_REGION: "ap-southeast-2"
    }
  },
  sentryDSN: "https://3459364239894fd4a1ef0ebfa366d8be@sentry.io/1318366",
  formConfigSchema: {
    uiSchema: {
      form: {
        //Tags: { "ui:field": Tags },
        Description: {
          "ui:widget": "textarea"
        },
        SubmitToUser: {
          "ui:help": "Separate email addresses with a comma"
        },
        NotiToEmail: {
          "ui:help": "Separate email addresses with a comma"
        },
        Slug: {
          "ui:readonly": true
        }
      }
    },
    schema: {
      title: "",
      description: "",
      type: "object",
      properties: {
        form: {
          title: "Form Config",
          type: "object",
          required: ["Title", "Owner", "Slug"],
          properties: {
            Owner: {
              type: "string",
              title: "Owner Email",
              format: "email"
            },
            Title: {
              title: "Title",
              type: "string"
            },
            Description: {
              type: "string",
              title: "Description"
            },
            Slug: {
              type: "string",
              title: "Slug"
            },
            Group: {
              type: "string",
              title: "Group",
              minLength: 2,
              maxLength: 30
            },
            SubmitToGroup: {
              title:
                "Department/s with access to form submissions (hold 'Ctrl' to select multiple or deselect)",
              type: "array",
              items: {
                type: "string",
                enum: ["-"]
              },
              uniqueItems: true
            },
            SubmitToUser: {
              type: "string",
              title: "Emails with access to form submissions"
            }
            // Tags: {
            //   type: "array",
            //   title: "Tags"
            // }
          }
        },
        emailNoti: {
          title: "Email Notification",
          type: "object",
          properties: {
            // NotiToGroup: {
            //   type: "array",
            //   items: {
            //     type: "string",
            //     enum: [""]
            //   },
            //   uniqueItems: true,
            //   title: "Send Noti to Group"
            // },
            NotiToEmail: {
              type: "string",
              title: "Email recipients"
            }
          }
        }
      }
    }
  }
};

export default GlobalConfig;
