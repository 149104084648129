import gql from "graphql-tag";

export default gql(`
  query {
    myAccInfo @client  {
        myUserName
        fullName
        email
    }
  }`);
