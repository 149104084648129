import React, { useState } from "react";
import moment from "moment";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ArrowRight from "@material-ui/icons/ArrowRight";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddAlert from "@material-ui/icons/AddAlert";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import MutationInviteRecord from "GraphQL/MutationInviteRecord";

const styles = theme => ({
  breadCrumbsWrapper: { marginBottom: 20 },
  breadCrumbIcon: { verticalAlign: "bottom", paddingBottom: 3, fontSize: 18 },
  textIput: {
    marginBottom: "20px"
  },
  submit: {
    backgroundColor: "#018FBC",
    color: "white",
    width: 120,
    boxShadow: "none",
    margin: 20,
    height: 50,
    padding: 0,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#018FBC"
    }
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  }
});

const FormInvite = ({ location, classes, client, history }) => {
  const [emails, setEmails] = useState("");
  const [phones, setPhones] = useState("");
  const [submitSccuess, setSubmitSccuess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    state: { formTitle, formId, form },
    pathname
  } = location;

  // BreadCrumbs
  const rootLink = pathname.split("/")[1];
  const correctCase =
    rootLink && rootLink.charAt(0).toUpperCase() + rootLink.substr(1);

  const handleEmailsChange = e => setEmails(e.target.value);
  const handlePhonesChange = e => setPhones(e.target.value);

  const handleSubmit = () => {
    // send mutation
    const {
      state: {
        myUserid,
        form: { id: formid }
      }
    } = location;

    let emailArr = emails ? emails.split(",") : [];
    emailArr = [...new Set(emailArr)];

    let phoneArr = phones ? phones.split(",") : [];
    phoneArr = [...new Set(phoneArr)];

    const mutationEmailArr = emailArr.map(email => {
      const recordData = {
        formid,
        userid: myUserid,
        created_at: moment().format("YYYY-M-D HH:MM:SS"),
        phone: "",
        email
      };

      return client.mutate({
        variables: recordData,
        mutation: MutationInviteRecord
      });
    });

    const mutationPhoneArr = phoneArr.map(phone => {
      const recordData = {
        formid,
        userid: myUserid,
        created_at: moment().format("YYYY-M-D HH:MM:SS"),
        phone,
        email: ""
      };

      return client.mutate({
        variables: recordData,
        mutation: MutationInviteRecord
      });
    });

    Promise.all([...mutationEmailArr, ...mutationPhoneArr])
      .then(result => {
        setSubmitSccuess(true);
        const redirect = setTimeout(() => {
          history.push("/");
        }, 3500);

        return redirect;
      })
      .catch(error => {
        setErrorMessage("Unable To Send Invite");
      });
  };

  return (
    <React.Fragment>
      <GridContainer direction="column">
        <GridItem sm={form ? 6 : 12} md={6} xs={12}>
          <div className={classes.breadCrumbsWrapper}>
            <Link
              to={{
                pathname: "/" + rootLink,
                state: { data: form }
              }}
            >
              <span className={classes.breadCrumbsInactive}>{correctCase}</span>
            </Link>
            <ArrowRight className={classes.breadCrumbIcon} />
            <span className={classes.breadCrumbsActive}>
              Invite management for {formTitle}
            </span>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={`${formTitle} form invite has been successfully sent! Redirecting to Forms page...`}
            open={submitSccuess}
            closeNotification={() => setSubmitSccuess(false)}
            close
          />
          <div className={classes.formContainer}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              label="Emails (Separate email addresses with a comma)"
              onChange={handleEmailsChange}
              className={classes.textIput}
            />
            <TextField
              variant="outlined"
              multiline
              fullWidth
              label="Phone numbers (Separate phone number with a comma)"
              onChange={handlePhonesChange}
              className={classes.textIput}
            />
            <Button
              className={classes.submit}
              variant="contained"
              onClick={handleSubmit}
            >
              submit
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default withApollo(withStyles(styles, { withTheme: true })(FormInvite));
