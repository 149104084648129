import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input";

class FormCurrencyInput extends React.Component {
  constructor(props) {
    super(props);
    const { formData } = props;
    this.state = {
      amount: formData || "0.00"
    };
  }

  handleChange = (event, maskedvalue, floatvalue) => {
    this.setState({ amount: floatvalue });
    this.props.onChange(floatvalue);
  };

  render() {
    const {
      schema: { title },
      required,
      readonly
    } = this.props;

    return (
      <React.Fragment>
        <div>
          <label>
            {title}
            {required ? "*" : null}
          </label>
        </div>

        <CurrencyInput
          value={this.state.amount}
          prefix="$"
          readOnly={readonly}
          onChangeEvent={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

FormCurrencyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object.isRequired,
  formData: PropTypes.number,
  schema: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  readonly: PropTypes.bool
};

export default FormCurrencyInput;
