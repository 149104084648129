import React from "react";
import PropTypes from "prop-types";
import { compose, graphql, withApollo } from "react-apollo";
import moment from "moment";
import Form from "react-jsonschema-form";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AddAlert from "@material-ui/icons/AddAlert";
import ArrowRight from "@material-ui/icons/ArrowRight";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Link } from "react-router-dom";
import MutationSubmitForm from "GraphQL/MutationSubmitForm";
import NoDataFound from "components/NoDataFound/NoDataFound";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Excel from "components/Download/Excel";
import CircularIndeterminate from "components/CircularProgress/CircularIndeterminate";
import QueryMyAccInfo from "GraphQL/QueryMyAccInfo";
import QueryFormEntry from "GraphQL/QueryFormEntry";
import QueryForm from "GraphQL/QueryForm";
import MyCookies from "config/Cookie";
import FormCurrencyInput from "components/Form/CurrencyInput";

const styles = theme => ({
  breadCrumbsWrapper: { marginBottom: 20 },
  breadCrumbIcon: { verticalAlign: "bottom", paddingBottom: 3, fontSize: 18 },
  button: {
    width: 120,
    boxShadow: "none",
    margin: 20,
    height: 50,
    padding: 0
  },
  loadingWrapper: {
    display: "flex",
    width: "100%",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    width: "95%",
    maxWidth: "900px",
    padding: "10px",
    marginBottom: "20px"
  },
  footer: {
    float: "right",
    marginBottom: "20px"
  },
  downloadLink: { textAlign: "right", paddingRight: "40px!important;" }
});

class HealthForm extends React.Component {
  redirect = null;
  constructor(props) {
    super(props);
    MyCookies.remove("entry_url");
    let formData = null;
    let {
      location: { state },
      formEntry = null
    } = this.props;

    if (state) {
      let { data: formDataObj, fullName } = state;

      formData =
        formDataObj && formDataObj.data
          ? JSON.parse(formDataObj.data)
          : { Name: fullName, DateCreated: moment().format("YYYY-M-D") };
    }

    if (formEntry) {
      formData = JSON.parse(formEntry.data);
    }

    this.state = {
      key: Date.now(),
      formData,
      submitSccuess: false,
      errorMessage: null
    };
  }

  onCancelButtonPressed = () => {
    this.setState({
      key: Date.now(),
      formData: this.state.formData
    });
  };

  onSubmit = ({ formData }) => {
    if (!formData) return;
    const {
      client,
      history,
      location: {
        state: {
          myUserid,
          form: { id: formid }
        }
      }
    } = this.props;

    client
      .mutate({
        variables: {
          formid,
          userid: myUserid,
          data: JSON.stringify(formData),
          datetime: moment().format("YYYY-M-D")
        },
        mutation: MutationSubmitForm
      })
      .then(result => {
        this.setState({ submitSccuess: true });
        this.onCancelButtonPressed();
        this.redirect = setTimeout(() => {
          history.push("/");
        }, 3500);
      })
      .catch(error => {
        this.setState({ errorMessage: "Unable To Submit Form" });
      });
  };

  componentWillUnmount() {
    clearTimeout(this.redirect);
  }

  render() {
    let {
      classes,
      form,
      location: { pathname, state: routeState },
      formEntry: data = null,
      loading
    } = this.props;

    if (loading) {
      return <CircularIndeterminate width={"250px"} />;
    }
    let { formData } = this.state;
    if (routeState) {
      form = routeState.form;
      data = routeState.data;
    }

    if (data && !formData) {
      formData = JSON.parse(data.data);
    }

    if (!form || !formData) {
      return <NoDataFound title={"Form render failed."} />;
    }

    const { schema: schemaJson, title, config: configJson, description } = form;
    const { uiSchema, schema } = JSON.parse(schemaJson);
    const config = JSON.parse(configJson);

    // if (config.form.Tags && config.form.Tags.length > 0) {
    //   schema.properties.Tags = {
    //     type: "array",
    //     title: "Taxonomies",
    //     items: {
    //       type: "string",
    //       enum: Object.values(config.form.Tags).map(n => n.text)
    //     },
    //     uniqueItems: true
    //   };
    //   uiSchema.Tags = {
    //     "ui:widget": "checkboxes"
    //   };
    // }

    if (schema.properties.EstimatedValue) {
      uiSchema.EstimatedValue = {
        "ui:field": FormCurrencyInput
      };
    }

    if (description) {
      schema.description = description;
    }

    for (const key of Object.keys(formData)) {
      if (!uiSchema[key]) uiSchema[key] = {};
      uiSchema[key]["ui:readonly"] = true;
    }

    const rootLink = pathname.split("/")[1];

    const correctCase =
      rootLink && rootLink.charAt(0).toUpperCase() + rootLink.substr(1);

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem sm={data ? 6 : 12} md={6} xs={12}>
            <div className={classes.breadCrumbsWrapper}>
              <Link
                to={{
                  pathname: "/" + rootLink,
                  state: { data: form }
                }}
              >
                <span className={classes.breadCrumbsInactive}>
                  {correctCase}
                </span>
              </Link>
              <ArrowRight className={classes.breadCrumbIcon} />
              {data
                ? [
                    <Link
                      key="link"
                      to={{
                        pathname: "/forms/entries",
                        state: { form }
                      }}
                    >
                      <span className={classes.breadCrumbsInactive}>
                        Entries
                      </span>
                    </Link>,
                    <ArrowRight
                      key="arrow"
                      className={classes.breadCrumbIcon}
                    />
                  ]
                : null}
              <span className={classes.breadCrumbsActive}>{title}</span>
            </div>
          </GridItem>
          {data ? (
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.downloadLink}>
                <Excel data={[formData]} />
              </div>
            </GridItem>
          ) : null}
        </GridContainer>
        <div className={classes.main}>
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={`${title} form has been successfully submitted! Redirecting to Forms page...`}
            open={this.state.submitSccuess}
            closeNotification={() => this.setState({ submitSccuess: false })}
            close
          />

          <Card className={classes.card}>
            <Form
              schema={schema}
              formData={formData}
              uiSchema={uiSchema}
              onSubmit={this.onSubmit}
            >
              {!data ? (
                <div className={classes.footer}>
                  <Button
                    type="button"
                    color="warning"
                    fullWidth={true}
                    className={classes.button}
                    onClick={this.onCancelButtonPressed}
                  >
                    <h4>Reset</h4>
                  </Button>
                  <Button
                    color="info"
                    fullWidth={true}
                    type="submit"
                    className={classes.button}
                  >
                    <h4>Submit</h4>
                  </Button>
                </div>
              ) : (
                <div />
              )}
            </Form>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

HealthForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  location: PropTypes.object,
  client: PropTypes.object,
  history: PropTypes.object,
  formEntry: PropTypes.object,
  form: PropTypes.object,
  loading: PropTypes.bool
};

export default withApollo(
  compose(
    graphql(QueryMyAccInfo, {
      skip: props => {
        const { location } = props;
        return !!location.state;
      },
      props: ({ data: { myAccInfo } }) => {
        return {
          myAccInfo
        };
      }
    }),
    graphql(QueryFormEntry, {
      skip: props => {
        const { location } = props;
        return !!location.state;
      },
      options: props => {
        const {
          location: { pathname }
        } = props;

        const id = pathname.match(
          /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        )[0];

        return {
          fetchPolicy: "cache-and-network",
          variables: {
            id
          }
        };
      },
      props: props => {
        const { data: formEntries } = props;
        const formEntry = formEntries ? formEntries.formEntry : null;
        return { formEntry };
      }
    }),
    graphql(QueryForm, {
      skip: props => {
        const { location } = props;
        return !!location.state;
      },
      options: props => {
        const { formEntry } = props;
        const { formId } = formEntry || { formId: null };
        return {
          fetchPolicy: "cache-and-network",
          variables: {
            id: formId
          }
        };
      },
      props: props => {
        const {
          data: { form = null, loading }
        } = props;

        return { form, loading };
      }
    })
  )(withStyles(styles, { withTheme: true })(HealthForm))
);
