import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import Amplify, { Auth, Hub } from "aws-amplify";
import gql from "graphql-tag";
import AWSAppSyncClient, {
  createAppSyncLink,
  createLinkWithCache
} from "aws-appsync";
import PropTypes from "prop-types";
import { ApolloLink } from "apollo-link";
import { withClientState } from "apollo-link-state";
import { AUTH_TYPE } from "aws-appsync/lib";
import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";
import { captureException } from "@sentry/browser";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import "assets/css/material-dashboard-react.css?v=1.4.1";
import indexRoutes from "routes/index.jsx";
import GlobalConfig from "./config/Global";
import MyCookies from "./config/Cookie";
import ErrorBoundary from "./ErrorBoundary";
import Login from "./views/Login/Login";

const hist = createBrowserHistory();
//window.LOG_LEVEL = "DEBUG";
const THEME = createMuiTheme({
  palette: {
    primary: grey
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    fontSize: 30,
    htmlFontSize: 20
  }
});

const stateLink = createLinkWithCache(cache =>
  withClientState({
    cache,
    resolvers: {
      Mutation: {
        updateMyAccInfo: (_, { myUserName, fullName, email }, { cache }) => {
          const data = {
            myAccInfo: {
              myUserName,
              fullName,
              email,
              __typename: "myAccInfo"
            }
          };
          cache.writeData({ data });
          return null;
        }
      }
    }
  })
);

Amplify.configure({
  Auth: {
    region:
      GlobalConfig[process.env.REACT_APP_STAGE].amp.REACT_APP_AWS_AUTH_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId:
      GlobalConfig[process.env.REACT_APP_STAGE].amp.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId:
      GlobalConfig[process.env.REACT_APP_STAGE].amp.REACT_APP_CLIENT_APP_ID, // User Pool App Client ID
    mandatorySignIn: false,
    oauth: GlobalConfig[process.env.REACT_APP_STAGE].oauth
  }
});

const clientLink = createAppSyncLink({
  url:
    GlobalConfig[process.env.REACT_APP_STAGE].appSync
      .REACT_APP_GRAPHQL_ENDPOINT,
  region:
    GlobalConfig[process.env.REACT_APP_STAGE].appSync
      .REACT_APP_AWS_CLIENT_REGION,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  }
});

const link = ApolloLink.from([stateLink, clientLink]);

const client = new AWSAppSyncClient({}, { link });

class WithProvider extends React.Component {
  state = { user: null };
  componentDidMount() {
    Hub.listen("auth", this);
    Auth.currentUserInfo().then(authInfo => {
      if (!authInfo) {
        if (
          /[^'*]entries\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
            window.location.href
          )
        ) {
          MyCookies.set("entry_url", window.location.pathname, { path: "/" });
        }
        return;
      }
      const { attributes } = authInfo;
      this.setState({ user: attributes });
    });
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === "auth" && payload.event === "signIn") {
      this.checkUser();
    }
  }

  checkUser() {
    Auth.currentUserInfo().then(authInfo => {
      if (!authInfo) {
        return;
      }
      const { attributes } = authInfo;
      const { sub: myUserName, name: fullName, email } = attributes;
      myUserName &&
        client
          .mutate({
            variables: { myUserName, fullName, email },
            mutation: gql`
              mutation updateMyAccInfo(
                $myUserName: ID!
                $fullName: String
                $email: String
              ) {
                updateMyAccInfo(
                  myUserName: $myUserName
                  fullName: $fullName
                  email: $email
                ) @client {
                  myUserName
                  fullName
                  email
                }
              }
            `
          })
          .then(result => {
            this.setState({ user: attributes });
          })
          .catch(error => {
            captureException(error);
          });
    });
  }

  render() {
    if (!client) {
      return <div />;
    }
    return (
      <ApolloProvider client={client}>
        <Rehydrated>
          <ErrorBoundary>
            <MuiThemeProvider theme={THEME}>
              <Router history={hist}>
                <Switch>
                  {this.state.user ? (
                    indexRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    })
                  ) : (
                    <Route component={Login} />
                  )}
                </Switch>
              </Router>
            </MuiThemeProvider>
          </ErrorBoundary>
        </Rehydrated>
      </ApolloProvider>
    );
  }
}

WithProvider.propTypes = {
  authState: PropTypes.string,
  authData: PropTypes.node
};

export default WithProvider;
