import gql from "graphql-tag";

export default gql(`
  query formEntry ($id:ID){
    formEntry(id:$id) {
        __typename
        id
        created_at
        modified_at
        userid
        status
        data
        formId
        creator
        formTitle
    }
  }`);
