import React from "react";
import ReactDOM from "react-dom";
import { init } from "@sentry/browser";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import GlobalConfig from "./config/Global";

init({
  dsn: GlobalConfig.sentryDSN,
  ignoreErrors: ["Expected to find a valid target"]
});

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
