import breadCrumbStyle from "./components/breadCrumbStyle";
import { hightLightColor } from "../material-dashboard-react";
const tableStyle = theme => ({
  ...breadCrumbStyle,
  menuItem: { width: 200, fontSize: 18 },
  selectStyle: { width: 200, fontSize: 18 },
  topBarRight: {
    textAlign: "right"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    boxShadow: "none"
  },
  table: {
    minWidth: 300
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#rgba(49,49,67,.35)"
    }
  },
  cardTitleWhite: {
    color: "#313143",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tableHeaderCell: {
    fontSize: "110%",
    color: "rgba(0, 0, 0, 0.5)",
    "& span": {
      fontSize: "100%",
      color: "rgba(0, 0, 0, 0.5)"
    }
  },
  tableCell: {
    fontSize: "110%",
    color: "rgba(0, 0, 0, 0.7)",
    "& a,& a:hover,& a:focus": {
      color: "rgba(0,0,0,.7)",
      fontSize: "110%",
      margin: "0",
      marginTop: "0",
      marginBottom: "0",
      textDecoration: "none; border-bottom: 1px solid rgba(0,0,0,.2);"
    },
    "& a:hover,& a:focus": {
      color: hightLightColor
    }
  },
  disabled: {
    color: "rgba(0,0,0, .3)"
  },
  headerRow: {
    height: "50px"
  },
  tableCellStatus: {
    color: "#313143",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "75%"
  },
  favouriteIcon: {
    cursor: "pointer"
  },
  cellTextAlignCenter: {
    textAlign: "center",
    padding: "none"
  }
});

export default tableStyle;
