import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose, graphql, withApollo } from "react-apollo";
import QueryForms from "GraphQL/QueryForms";
import EnhancedTableHead from "components/EnhancedTableHead/EnhancedTableHead";
import QueryMyAccInfo from "GraphQL/QueryMyAccInfo";
import QueryMyProfile from "GraphQL/QueryMyProfile";
import CircularIndeterminate from "components/CircularProgress/CircularIndeterminate";
import NoDataFound from "components/NoDataFound/NoDataFound";
import TableCell from "@material-ui/core/TableCell";
import GlobalConfig from "config/Global";
import MyCookies from "config/Cookie";
import tableStyle from "assets/jss/material-dashboard-react/tableStyle";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTable extends React.Component {
  state = {
    busy: false,
    order: "asc",
    orderBy: "calories",
    selected: [],
    page: 0,
    rowsPerPage: 10,
    nextToken: this.props.nextToken
  };

  componentDidMount() {}

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getHeaderCols = () => {
    const headerCol = [
      {
        id: "title",
        string: true,
        disablePadding: false,
        label: "TITLE",
        canSort: true,
        padding: "none"
      },
      {
        id: "siteid",
        string: false,
        disablePadding: false,
        label: "Type",
        canSort: true
      },
      {
        id: "action",
        string: false,
        disablePadding: false,
        label: "ACTION",
        canSort: false
      }
    ];

    return headerCol;
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const redirect_path = MyCookies.get("entry_url");

    if (MyCookies.get("entry_url")) {
      return <Redirect to={redirect_path} key={"redirect"} />;
    }
    const {
      classes,
      loading,
      error,
      forms: data,
      myAccInfo,
      profile
    } = this.props;

    const { role, department } = profile || { role: ["PUBLIC"] };

    if (loading || !data || !myAccInfo) {
      return <CircularIndeterminate width={"250px"} />;
    }

    if (error || data.length <= 0) {
      return <NoDataFound title={"Form render failed."} />;
    }

    const { myUserName: myUserid, fullName, email } = myAccInfo;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const headerRows = this.getHeaderCols();
    const adminRole = role.includes(
      `${GlobalConfig.appName}-${GlobalConfig.adminRole}`
    );

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table
            aria-labelledby="tableTitle"
            className={classes.table}
            style={{ tableLayout: "auto" }}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              headerRows={headerRows}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => {
                  const { config } = data;
                  let slug,
                    owner = "";
                  let isInGroup = false;
                  if (config) {
                    const configObject = JSON.parse(config);
                    const {
                      form: {
                        Slug: form_slug,
                        Owner: form_owner,
                        SubmitToGroup = []
                      }
                    } = configObject;
                    slug = form_slug;
                    owner = form_owner;
                    isInGroup =
                      SubmitToGroup.filter(g => g === department).length !== 0;
                  }

                  return (
                    <TableRow
                      className={classes.row}
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell className={classes.tableCell}>
                        <Link
                          to={{
                            pathname: slug
                              ? `/forms/${slug}`
                              : `/forms/${data.id}`,
                            state: { form: data, fullName, myUserid }
                          }}
                        >
                          {data.title}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {+data.siteid === 1 && "Internal"}
                        {+data.siteid === 2 && "Public"}
                        {+data.siteid === 3 && "Anonymous"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {adminRole || owner === email ? (
                          <Link
                            key={"cofig-link"}
                            to={{
                              pathname: `/forms/config`,
                              state: {
                                form: data,
                                myUserid
                              }
                            }}
                          >
                            Config
                          </Link>
                        ) : (
                          <span className={classes.disabled}>Config</span>
                        )}
                        <span key={"spacer"}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        {data.showEntry || adminRole || isInGroup ? (
                          <Link
                            to={{
                              pathname: `/forms/entries`,
                              state: {
                                form: data,
                                myUserid,
                                formTitle: data.title
                              }
                            }}
                          >
                            Entries
                          </Link>
                        ) : (
                          <span className={classes.disabled}>Entries</span>
                        )}
                        <span key={"spacer"}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        {adminRole || owner === email ? (
                          <Link
                            to={{
                              pathname: `/forms/invite`,
                              state: {
                                form: data,
                                formTitle: data.title,
                                formId: data.id,
                                myUserid
                              }
                            }}
                          >
                            Invite
                          </Link>
                        ) : (
                          <span className={classes.disabled}>Invite</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[10]}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  nextToken: PropTypes.string,
  forms: PropTypes.array,
  myAccInfo: PropTypes.object,
  profile: PropTypes.object
};

export default withApollo(
  compose(
    graphql(QueryMyAccInfo, {
      props: ({ data: { myAccInfo } }) => {
        return {
          myAccInfo
        };
      }
    }),
    graphql(QueryMyProfile, {
      options: ({ myAccInfo }) => {
        const { myUserName } = myAccInfo || {
          fullName: "",
          myUserName: ""
        };

        return {
          fetchPolicy: "cache-and-network",
          variables: {
            id: myUserName
          }
        };
      },
      props: ({ data }) => {
        const { user: profile } = data || { user: {} };
        return { profile };
      }
    }),
    graphql(QueryForms, {
      options: ({ myAccInfo }) => {
        return {
          fetchPolicy: "cache-and-network",
          variables: {
            nextToken: "",
            limit: 100,
            username: "",
            userid: "",
            filter: ""
          }
        };
      },
      props: ({
        data: { loading, error, forms = { forms: null }, nextToken }
      }) => {
        const { forms: formRecords } = forms;
        return {
          loading,
          error,
          forms: formRecords,
          nextToken: null
        };
      }
    })
  )(withStyles(tableStyle, { withTheme: true })(EnhancedTable))
);
