import gql from "graphql-tag";

export default gql(`
  query ($id: ID!){
    user(id:$id) {
      __typename
      id
      email
      name
      role
      department
    }
  }`);
