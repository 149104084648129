import gql from "graphql-tag";

export default gql(`
  query ($nextToken: String, $limit:Int,$userid:ID, $filter: String){
    forms(nextToken: $nextToken, limit: $limit, userid:$userid, filter:$filter) {
      forms{
        __typename
        id
        created_at
        modified_at
        userid
        status
        schema
        title
        description
        config
        showEntry
        siteid
      }
      nextToken
    }
  }`);
