import gql from "graphql-tag";

export default gql(`
  query ($id:ID){
    form(id:$id) {
      __typename
      id
      created_at
      modified_at
      userid
      status
      schema
      title
      description
      config
      showEntry
      siteid
    }
  }`);
