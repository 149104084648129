import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import CloudDownLoad from "@material-ui/icons/CloudDownload";
import { hightLightColor } from "assets/jss/material-dashboard-react";

const Excel = props => {
  const { data } = props;
  let exportData = [],
    headers = [];
  if (typeof data[0] === "string" || data[0] instanceof String) {
    exportData = data.map(n => {
      return JSON.parse(n);
    });
    headers = Object.keys(exportData[0]);
  } else {
    headers = Object.keys(data[0]);
    exportData = data;
  }

  return (
    <CSVLink data={exportData} headers={headers}>
      <CloudDownLoad style={{ color: hightLightColor }} />
    </CSVLink>
  );
};

Excel.propTypes = {
  data: PropTypes.array.isRequired
};

export default Excel;
