import React from "react";
import { withOAuth } from "aws-amplify-react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import CircularIndeterminate from "components/CircularProgress/CircularIndeterminate";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import styles from "assets/jss/material-dashboard-react/views/loginStyle";

const logo = require("assets/img/logo.png");

class Login extends React.Component {
  redirect = null;

  componentDidMount() {
    this.redirect = setTimeout(() => {
      this.props.OAuthSignIn();
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.redirect);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <GridItem xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardBody
              style={{
                textAlign: "center"
              }}
            >
              <img src={logo} alt={"logo"} className={classes.logo} />
              <h3>Welcome to Neami Blue Portal</h3>
              <CircularIndeterminate width={"120px"} />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object,
  OAuthSignIn: PropTypes.func
};

export default withStyles(styles)(withOAuth(Login));

/*
<img src={logo} alt="logo" />
<Button
  color="info"
  className={classes.loginButton}
  onClick={this.props.OAuthSignIn}
>
  Sign in with Neami account
</Button>

*/
