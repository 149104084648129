import React from "react";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import tableStyle from "../../assets/jss/material-dashboard-react/tableStyle";

class enhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      order,
      orderBy,
      classes,
      headerRows,
      multiSelect,
      onSelectAllClick,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.headerRow}>
          {multiSelect ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                color="default"
              />
            </TableCell>
          ) : null}

          {headerRows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                variant={"head"}
                className={classes.tableHeaderCell}
              >
                {row.canSort ? (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel active={false} className={classes.tableCell}>
                    {row.label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

enhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headerRows: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func,
  multiSelect: PropTypes.bool
};

export default withStyles(tableStyle, { withTheme: true })(enhancedTableHead);
