import React from "react";
import PropTypes from "prop-types";
import { compose, graphql, withApollo } from "react-apollo";
import moment from "moment";
import Form from "react-jsonschema-form";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import AddAlert from "@material-ui/icons/AddAlert";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { Link } from "react-router-dom";
import MutationSaveConfig from "../../GraphQL/MutationSaveConfig";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GlobalConfig from "../../config/Global";
import QueryUserDepartments from "../../GraphQL/QueryUserDepartments";
import QueryMyAccInfo from "../../GraphQL/QueryMyAccInfo";
import breadCrumbStyle from "../../assets/jss/material-dashboard-react/components/breadCrumbStyle";
import NoDataFound from "components/NoDataFound/NoDataFound";

const styles = theme => ({
  ...breadCrumbStyle,
  button: {
    width: 120,
    boxShadow: "none",
    margin: 20,
    height: 50,
    padding: 0
  },
  loadingWrapper: {
    display: "flex",
    width: "100%",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    width: "95%",
    maxWidth: "900px",
    padding: "10px",
    marginBottom: "20px"
  },
  footer: {
    float: "right",
    marginBottom: "20px"
  }
});

const CustomSnackBar = ({
  isConfigForm,
  closeNotification,
  title,
  openSnackbar
}) => {
  return (
    <Snackbar
      place="tc"
      color="success"
      icon={AddAlert}
      message={`${title} saved successfully!`}
      open={openSnackbar}
      closeNotification={closeNotification}
      close
    />
  );
};

CustomSnackBar.propTypes = {
  isConfigForm: PropTypes.bool,
  closeNotification: PropTypes.func,
  openSnackbar: PropTypes.bool,
  title: PropTypes.string
};

const { uiSchema, schema } = GlobalConfig.formConfigSchema;

class ConfigForm extends React.Component {
  redirect = null;
  constructor(props) {
    super(props);
    const {
      location: {
        state: {
          form: { config, title, description }
        }
      },
      myAccInfo: { email },
      departments: departmentsObj
    } = props;

    const departments = departmentsObj
      ? Object.keys(departmentsObj).map(i => departmentsObj[i].department)
      : [];

    if (departments) {
      schema.properties.form.properties.SubmitToGroup.items.enum = [
        ...departments
      ];
    }

    const formData = config
      ? JSON.parse(config)
      : {
          form: {
            Title: title,
            Description: description
          }
        };
    if (!formData.form.Owner) {
      formData.form.Owner = email;
    }
    formData.form.Slug = title.replace(/[^A-Z0-9]+/gi, "-").toLowerCase();
    this.state = {
      formData,
      key: Date.now(),
      submitSccuess: false,
      errorMessage: null
    };

    this.debouncedFormSubmit = _.debounce(this.onSubmit, 1000, {
      maxWait: 1000
    });
  }
  closeNotification = () => {
    this.setState({ submitSccuess: false });
  };
  onCancelButtonPressed = () => {
    this.setState({
      key: Date.now(),
      formData: this.state.formData
    });
  };

  onSubmit = ({ formData }) => {
    if (!formData) return;
    const {
      client,
      location: {
        state: {
          myUserid,
          form: { id: formid, userid: creator }
        }
      }
    } = this.props;
    const {
      form: { Title, Description }
    } = formData;

    client
      .mutate({
        variables: {
          formid,
          userid: creator,
          title: Title,
          description: Description,
          modified_by: myUserid,
          data: JSON.stringify(formData),
          datetime: moment().format("YYYY-M-D")
        },
        mutation: MutationSaveConfig
        //refetchQueries: [QueryForms]
      })
      .then(result => {
        this.setState({ submitSccuess: true, formData });
        this.redirect = setTimeout(() => {
          this.setState({ submitSccuess: false });
        }, 4000);
      })
      .catch(error => {
        this.setState({ errorMessage: "Unable To Submit Form" });
      });
  };

  deleteForm = () => {};

  componentWillUnmount() {
    clearTimeout(this.redirect);
  }

  render() {
    const {
      classes,
      location: {
        pathname,
        state: {
          form: { id }
        }
      }
    } = this.props;

    if (!id) {
      return <NoDataFound title={"Form render failed."} />;
    }

    const rootLink = pathname.split("/")[1];

    const correctCase =
      rootLink && rootLink.charAt(0).toUpperCase() + rootLink.substr(1);
    const isConfigForm = pathname.includes("/config");

    return (
      <React.Fragment>
        <div className={classes.breadCrumbsWrapper}>
          <Link
            to={{
              pathname: "/"
            }}
          >
            <span className={classes.breadCrumbsInactive}>{correctCase}</span>{" "}
          </Link>
          <ArrowRight className={classes.breadCrumbIcon} />
          <span className={classes.breadCrumbsActive}>
            Config of {this.state.formData.form.Title}
          </span>
        </div>

        <div className={classes.main}>
          <CustomSnackBar
            title={this.state.formData.form.Title}
            closeNotification={this.closeNotification}
            openSnackbar={this.state.submitSccuess}
            isConfigForm={isConfigForm}
          />
          <Card className={classes.card}>
            <Form
              schema={schema}
              formData={this.state.formData}
              uiSchema={uiSchema}
              onSubmit={this.debouncedFormSubmit}
            >
              <div className={classes.footer}>
                <Button
                  color="danger"
                  simple={true}
                  className={classes.deleteButton}
                >
                  Delete
                </Button>
                <Button
                  color="info"
                  fullWidth={true}
                  type="submit"
                  className={classes.button}
                >
                  <h4>Submit</h4>
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

ConfigForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  myAccInfo: PropTypes.object.isRequired,
  location: PropTypes.object,
  client: PropTypes.object,
  history: PropTypes.object,
  departments: PropTypes.array
};

export default withApollo(
  compose(
    graphql(QueryMyAccInfo, {
      props: ({ data: { myAccInfo } }) => {
        return {
          myAccInfo
        };
      }
    }),
    graphql(QueryUserDepartments, {
      props: ({ data: { departments } }) => {
        return {
          departments
        };
      }
    })
  )(withStyles(styles, { withTheme: true })(ConfigForm))
);
