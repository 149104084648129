const style = {
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: "1",
    //backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: "#375365",
      opacity: ".7"
    }
  },
  card: {
    zIndex: "4",
    backgroundColor: "rgba(0,0,0,.2)",
    "& h3": {
      color: "rgba(255,255,255,.7)"
    }
  },
  fullHeight: {
    height: "100%"
  },
  loginButton: {
    width: "100%"
  },
  logo: {
    width: "250px"
  }
};

export default style;
