import React from "react";
import classNames from "classnames";
import { Auth } from "aws-amplify";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    user: { email: "", name: "" }
  };
  constructor() {
    super();
    Auth.currentUserInfo().then(({ attributes }) => {
      this.setState({ user: attributes });
    });
    Auth.currentSession();
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = async event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });

    await Auth.signOut();

    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const {
      user: { email = "", name = "" } = { email: "", name: "" }
    } = this.state;

    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Profile
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <div className={classes.dropDownloadPanel}>
                    <div className={classes.label}>Email</div>
                    <div className={classes.value}>{email}</div>
                    <div className={classes.label} style={{ marginTop: 5 }}>
                      Name
                    </div>
                    <div className={classes.value}>{name}</div>
                  </div>

                  <MenuList role="menu">
                    <MenuItem
                      onClick={this.handleClose}
                      className={classes.dropdownItem}
                    >
                      <div
                        style={{
                          float: "left",
                          padding: 5
                        }}
                      >
                        <PowerSettingsNew />
                      </div>
                      <div
                        style={{
                          float: "left",
                          paddingTop: 10
                        }}
                      >
                        LOGOUT
                      </div>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
